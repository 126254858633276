import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../components/layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My overall objective is to create apps with simple architectures using modular
programming in serverless environments. I have 6+ years of experience designing,
architecting, and launching web applications with interactive front-ends, REST
and GraphQL APIs, and various data stores.`}</p>
    <h2>{`Work summary`}</h2>
    <h3>{`Cycles`}</h3>
    <h4>{`Los Angeles, CA`}</h4>
    <h5>{`Software Engineer and Cloud Architect`}</h5>
    <h6>{`Oct 2017 – Nov 2019`}</h6>
    <p>{`Developed and deployed four UIs using ES6+, Babel, webpack, React, and Redux
embedded throughout the Shopify platform.`}</p>
    <p>{`Developed and deployed a 25 route REST API connecting to PostgreSQL, DynamoDB,
Shopify, and Stripe using Node.js.`}</p>
    <p>{`Configured serverless architecture across independent production, staging, and
development environments.`}</p>
    <h3>{`KCHUNG Radio`}</h3>
    <h4>{`Chinatown, Los Angeles, CA`}</h4>
    <h5>{`Senior Software Engineer`}</h5>
    <h6>{`Nov 2017 – Present`}</h6>
    <p>{`Designed, developed, and deployed the KCHUNG NewsBody web app using React,
Next.js, GraphQL, PostgreSQL, AWS S3, and DynamoDB with JWT based
authentication.`}</p>
    <p>{`Developed interactivity for the KCHUNG Radio web site using vanilla JS.`}</p>
    <p>{`Developed scripts to sync radio archives to AWS S3 and PostgreSQL.`}</p>
    <p>{`Developed an API to retrieve paginated show data from PostgreSQL.`}</p>
    <p>{`Developed an API to filter monthly schedule data obtained from the Google
Calendar API.`}</p>
    <p>{`Managed DNS, MySQL and PostgreSQL databases, S3, IAM, Linux web server, and G
Suite.`}</p>
    <h3>{`RGB DEV`}</h3>
    <h4>{`Los Angeles, CA`}</h4>
    <h5>{`Software Lead`}</h5>
    <h6>{`May 2015 – Present`}</h6>
    <p>{`Developed and deployed a sales application using React, Next.js, and Airtable as
an admin interface.`}</p>
    <p>{`Designed, developed, and deployed an inventory tracking application to
streamline warehouse activity, providing check-in/out functionality for hundreds
of locations using Meteor, React, and MongoDB.`}</p>
    <p>{`Modified legacy PHP inventory software to enable warehouse workers the ability
to scan items in bulk.`}</p>
    <p>{`Provisioned Debian servers and migrated legacy software and MySQL database.`}</p>
    <h3>{`Sony DADC`}</h3>
    <h4>{`Marina del Rey, CA`}</h4>
    <h5>{`Senior Application Support Engineer`}</h5>
    <h6>{`Nov 2012 – Sep 2015`}</h6>
    <p>{`Designed, created, and administered SQL databases, managed SQL reports, bulk
data fixes, users, etc.`}</p>
    <p>{`Developed internal web tools and scripts using Python, Flask, JavaScript, and
SQLAlchemy to interface with production databases.`}</p>
    <p>{`Supported development team by writing SQL queries, identifying and debugging
issues in Java, C#, ASP.NET, and submitting feature requests and bug reports.`}</p>
    <p>{`Managed and trained new employees.`}</p>
    <h3>{`3 Days Awake`}</h3>
    <h4>{`Los Angeles, CA`}</h4>
    <h5>{`Software Engineer`}</h5>
    <h6>{`Jun 2011 – Nov 2012`}</h6>
    <p>{`Automated image processing and generated HTML/PDFs from TIFF image files using
Python, Flask, PostgreSQL, PIL, and Jinja2.`}</p>
    <p>{`Worked with curators to design and develop websites for each of the gallery’s
art openings.`}</p>
    <h3>{`Warner Bros. Records`}</h3>
    <h4>{`Burbank, CA`}</h4>
    <h5>{`Video Engineer`}</h5>
    <h6>{`Nov 2008 – Mar 2011`}</h6>
    <p>{`Wrote transcoding scripts to create archive quality digital masters from various
master sources (HDCAM SR, Digibeta, Uncompressed MOV/AVI, 1” Tape).`}</p>
    <p>{`Encoded and compressed digital masters into a variety of file types and codecs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      